import React, {useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {
    GET_COLLECTION_DATA
} from "../../../api";
import cogoToast from "cogo-toast";
import {getUser, getPreSignedUrl, collector, uploadPhoto, waste, user, zone} from "../../../api/app";
import {capitalize} from "../../../utils/capitalize";
import {truncate} from "../../../utils/trunate";
import ViewGMap from "../../../components/map/ViewGMap";
const Buffer = require('buffer/').Buffer;

const Data = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const [loading, setLoading] = useState(true);
    const [openMap, setOpenMap] = useState(false);
    const [coordinate, setCoordinate] = useState({});
    const [userData, setUserData] = useState(null);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [images, setImages] = useState([]);
    const [datatable] = useState({
        waste: false,
        address: false,
        credit: false,
        report: false
    });

    useEffect(() => {
        getUser(id).then((response) => {
            if(!response.error) {
                setUserData(response.payload);
                setLoading(false);
            }
        });
    }, [refresh]);

    const wasteDatatable = () => {
        if(datatable.waste) return false;
        datatable.waste = true;
        let table = $('#waste_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Waste",
                        fieldname: "name",
                        filter: {
                            type: "subscription",
                            user: userData._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }

                            },
                            {
                                path: 'user',
                                model: 'user',
                            },
                            {
                                path: 'transaction',
                                model: 'transaction',
                            },
                            {
                                path: 'product',
                                model: 'product',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return capitalize(data.type);
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.type === "subscription" ? `${userData.country.currency.symbol}${data.meta.amount*data.total_month*data.bin}` : data.meta.amount <= 0 ? "---" : data.meta.amount;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.type === "subscription" ? capitalize(data.product?.type) : "Pickup";
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            "paid" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "credit" : `<span class="badge badge-warning tx-white">${capitalize(data.status)}</span>`,
                            "expired" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`,
                            "pending" : `<span class="badge badge-warning tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return data.status !== "pending" ? data.due_date.str > moment(new Date()).format("YYYY/MM/DD hh:mm") ? `<span class="tx-purple">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="tx-danger">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="badge badge-warning tx-white">Pending</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.delete_waste', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                waste("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#waste_table').DataTable().ajax.reload();
                        cogoToast.success("Waste successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        $('#waste_table tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        table.on('click', '.delete-waste', function (e) {
            e.preventDefault();
            $(".data-message").text(`Are you sure, you want to delete this waste?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                waste("delete", {id: e.target.id}).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#waste_table').DataTable().ajax.reload();
                        cogoToast.success(`Document successfully removed.`, {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        function format(d) {
            // `d` is the original data object for the row
            const status = (data) => {
                return {
                    "renew-subscription" : `<span class="text-capitalize">Renew</span>`,
                    "credit-subscription" : `<span class="text-capitalize">Credit</span>`,
                    "subscription" : `<span class="text-capitalize">New</span>`
                }[data?.transaction?.metadata?.type] || `<span class="text-capitalize">Subscription<span class="text-danger">Unknown</span></span>`;
            }

            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Collector:</td>' +
                '<td>' +
                `<div class="d-flex">
                    <div class="avatar wd-20 ht-20"><img src="${d.raw.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                    <span class="pd-x-5">${d.raw.collector.name}</span>
                </div>` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Total Amount:</td>' +
                '<td>' +
                d.raw.collector.country.currency.symbol + d.raw.meta.amount * d.raw.total_month * d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Bin:</td>' +
                '<td>' +
                d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Total Month:</td>' +
                '<td>' +
                d.raw.total_month +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<tr>' +
                '<td>Transaction #:</td>' +
                '<td>' +
                (d.raw.status === "credit" ? "Pending" : `${d.raw.transaction.txn_number.toUpperCase()} - ${status(d.raw)}`) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>' + moment(d.raw.date.iso).format("Do MMM, YYYY") + '</td>' +
                '</tr>' +
                '<tr>' +
                '<td class="tx-13 pd-0">' +
                '<div class="d-flex">Action: ' +
                `<a href="#" id="${d.raw._id}" class="nav-link pd-t-0 edit-waste"><i class="fa fa-pencil"></i> Edit</a>` +
                `<a href="#" id="${d.raw._id}" class="nav-link pd-t-0 pd-l-0 delete-waste"><i class="fa fa-trash-alt"></i> Delete</a>` +
                '</div>' +
                '</td>' +
                '</tr>' +
            '</table>'
        )
            ;
        }
    }

    const addressDatatable = () => {
        if (datatable.address) return false;
        datatable.address = true;

        let table = $('#address_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Address Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "User",
                        fieldname: "name",
                        filter: {
                            _id: userData._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data[0].addresses.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function (err) {
                        cogoToast.error(err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {
                            position: "top-right",
                            hideAfter: 5
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.unit_number;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        console.log(data)
                        return data.meta.street_address;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.meta.suburb;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.meta.city;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view-map"><i class="fa fa-map"></i> View Map</a> <a href="#" class="nav-link delete-address"><i class="fa fa-trash"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete-address', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id,
                    action: "delete-address",
                    address_id: extract_td.raw._id
                }

                user("patch", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#address_table').DataTable().ajax.reload()
                        cogoToast.success("Address successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.view-map', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            setCoordinate({lat: extract_td.raw.location.coordinates[0], lng: extract_td.raw.location.coordinates[1]});

            $("#modalMap").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });
    }

    const creditDatatable = () => {
        if(datatable.driver) return false;
        datatable.driver = true;
        $('#credit_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Credit Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Credit",
                        fieldname: "name",
                        filter: {
                            user: userData._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.first_name} ${data.last_name}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.id_number;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link approve_company"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_company"><i class="fa fa-pencil"></i> Edit</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });
    }

    const reportDatatable = () => {
        if(datatable.report) return false;
        datatable.report = true;
        let table = $('#report_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Report Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Report",
                        fieldname: "name",
                        filter: {
                            user: userData._id
                        },
                        pageSize: data.start,
                        populate: "user",
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return truncate(data.description, 70);
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.type;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            resolved : `<span class="badge badge-success tx-white">Resolved</span>`,
                            unresolved : `<span class="badge badge-warning tx-white">Unresolved</span>`,
                            rejected : `<span class="badge badge-danger tx-white">Rejected</span>`
                        }[data.status];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_report"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_company"><i class="fa fa-trash"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_report', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            setImages(extract_td.raw.photos);
            $("#tx-description").text(extract_td.raw.description);
            $("#tx-type").text(extract_td.raw.type);
            $("#tx-status").text(extract_td.raw.status);
            $("#tx-location").text(extract_td.raw.address);
            $("#tx-date").text(moment(extract_td.raw.date.iso).format("Do MMM, YYYY"));
            $("#tx-user").text(capitalize(extract_td.raw.user.first_name));

            $("#modalReport").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    const handleModalProduct = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#product_form').parsley();
        $("#modalProduct").modal({ backdrop: 'static', focus: false, show: true });
    };

    const modalUserForm = () => {
        $('#user_form').parsley();
        $("#first_name").val(userData.first_name);
        $("#last_name").val(userData.last_name);
        $("#gender").val(userData.gender);
        $("#mobile").val(userData.mobile_number);
        $("#modalUser").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleUserSubmit = async(e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            id: userData._id,
            first_name : $("#first_name").val(),
            last_name: $("#last_name").val(),
            gender : $("#gender").val(),
            mobile_number : $("#mobile").val(),
            password: $("#password").val()
        }

        user("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                setRefresh(new Date().getTime());
                resetForm("modalUser", "user_form");
            }
        })

        return false;
    }

    const modalAddressForm = () => {

    }

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Collector</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-none d-md-block">
                            {userData.gender ? <span className="badge badge-success tx-white">Active</span> :
                                <span className="badge badge-danger tx-white">Inactive</span>}
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div
                                className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">
                                {userData.photo_uri ?
                                    <img src={userData.photo_uri} className="img-fit-cover rounded-5" alt="logo"/> :
                                    <span className="link-03 lh-0 text-center"><i style={{fontSize: 50}} className="icon ion-md-camera"></i><p className="text-center tx-color-03 mt-2 tx-13">No Photo</p></span>}
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5 text-capitalize">{userData.first_name} {userData.last_name}</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span className="tx-medium tx-color-02 text-capitalize">{userData.gender}</span><br/>
                                    {userData.country.name}
                                </p>
                                <span className="d-block tx-13 tx-color-03">{userData.mobile_number}</span>
                            </div>
                        </div>
                        <div className="d-none d-md-block">
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={modalUserForm.bind()}>
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Edit Profile
                            </button>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> User Info</a></li>
                                        <li className="nav-item"><a href="#address" className="nav-link" data-toggle="tab" onClick={addressDatatable.bind()}><i className="ion-md-locate"/> Addresses</a></li>
                                        <li className="nav-item"><a href="#waste" className="nav-link" data-toggle="tab" onClick={wasteDatatable.bind()}><i className="ion-md-trash"/> Wastes</a></li>
                                        <li className="nav-item"><a href="#credit" className="nav-link" data-toggle="tab" onClick={creditDatatable.bind()}><i className="ion-md-cash"/> Credits</a></li>
                                        <li className="nav-item"><a href="#report" className="nav-link" data-toggle="tab" onClick={reportDatatable.bind()}><i className="ion-md-paper-plane"/> Reports</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Mobile #: </strong> {userData.mobile_number}</p>
                                            <p className="text-capitalize"><strong>Gender: </strong> {userData.gender}</p>
                                            <p><strong>Country: </strong> {capitalize(userData.country.name)}</p>
                                            <p><strong>Device: </strong>{userData.device?.mobile.brand_name ? `${capitalize(userData.device.mobile.model) + `, OS Version ` + capitalize(userData.device.mobile.version)}` : "Unregistered"}</p>
                                            <p><strong>Last Login: </strong> {moment(userData.last_login.str).format("Do MMM, YYYY")}</p>
                                            <p><strong>Date Created: </strong> {moment(userData.date.str).format("Do MMM, YYYY")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="waste" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Wastes</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#waste_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="waste_table" className="table"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="wd-20p">Type</th>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Product</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Expiry Date</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="address" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Addresses</h6>
                                                    <div className="d-flex tx-18">
                                                        <a className="tx-13 link-03 d-flex align-items-center lh-0 mg-l-10" href="javascript:void(0)" onClick={modalAddressForm.bind()}><span className="icon ion-md-add"> Add Address</span></a>
                                                        <a href="javascript:void(0)" className="tx-13 link-03 d-flex align-items-center lh-0 mg-l-10" onClick={() => $('#address_table').DataTable().ajax.reload()}><span className="icon ion-md-refresh tx-normal"> Reload</span></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="address_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-10p">Unit #</th>
                                                            <th className="wd-30p">Street Address</th>
                                                            <th className="wd-20p">Area</th>
                                                            <th className="wd-20p">City</th>
                                                            <th className="wd-20p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="credit" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Credits/Wallet</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#credit_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={handleModalProduct.bind()}><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="credit_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Points</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="report" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Report</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#report_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="report_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Description</th>
                                                            <th className="wd-20p">Type</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                         aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalUser", "user_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">Edit User</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Edit user information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="user_form" className="parsley-style-1" onSubmit={handleUserSubmit.bind()}>
                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="firstNameWrapper" className="form-group parsley-input">
                                            <label>First Name</label>
                                            <input id="first_name" type="text" className="form-control"
                                                   placeholder="Enter first name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#firstNameWrapper"/>
                                        </div>

                                        <div id="lastNameWrapper" className="form-group parsley-input">
                                            <label>Last Name</label>
                                            <input id="last_name" type="text" className="form-control"
                                                   placeholder="Enter last name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#lastNameWrapper"/>
                                        </div>

                                        <div id="genderWrapper" className="form-group parsley-input">
                                            <label>Gender</label>
                                            <select className="custom-select" id="gender">
                                                <option value="" disabled selected>Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>

                                        <div id="passwordWrapper" className="form-group parsley-input">
                                            <label>Password</label>
                                            <input id="password" type="text" className="form-control"
                                                   placeholder="Enter password"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#passwordWrapper"/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            <button className="btn btn-success btn-block">Update User</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal" aria-label="Close"><span
                                        aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Report</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Reported By: <span
                                            id="tx-user">---</span></p></div>
                                </div>
                                <div className="modal-body pd-sm-b-40 pd-sm-x-30">
                                    <p><strong>Description:</strong> <span id="tx-description">---</span></p>
                                    <p><strong>Type:</strong> <span id="tx-type">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                    {images.length > 0 ? <PhotoProvider>
                                        <p className="tx-primary">Click Photos Below</p>
                                        <div className="img-group float-left">
                                            {images.map((item, index) => (
                                                <PhotoView key={index} src={item}>
                                                    <img src={item} className="img wd-60 ht-60 rounded-circle" alt=""/>
                                                </PhotoView>
                                            ))}
                                        </div>
                                    </PhotoProvider> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalMap" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span
                                        aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">View Map</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Map information.</p>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    {openMap ? <ViewGMap coordinate={coordinate}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default Data;
